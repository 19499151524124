import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import classes from "./Modals.module.css";

export const InformationModal = (props) => {
return (
        <ModalWindow show={props.show} onClose={props.onClose} title='About The Electoral Vote Simulator'>
            <p>While reading some political sites, I saw an article theorizing that Donald Trump might run as a third party candidate in 2024 if he wasn't the Republican nominee.</p>
            <p>This got me to thinking: "What if 2020's electoral results were repeated in 2024, but a third party candidate peeled off X% of the Republican vote? What would change?"</p>
            <p>To remain non-partisan, I included the capability to add a third party candidate on the left as well.</p>
            <p>Later on, as it became clear that Joe Biden and Donald Trump would be the 2024 nominees, I saw some people saying they'd switch parties or stay home (in addition to the third party option). So I added these into the calculations.</p>
            <p>To change the results, move the sliders to give each more or less percentage of the votes. As you do, electoral results will change and some states might switch from red to blue, vice versa, or even go orange (right wing third party) or green (left wing third party).</p>
            <p>I hope you enjoy this.</p>
            <p>-TechyDad</p>

            <p>Credits:</p>
            <p>Top images are from <a href='https://www.flaticon.com/packs/essential-elements-10' target='_blank' rel='noreferrer'>UnicornLabs' free Essential Elements Glyph pack</a>.</p>
            <p>Favicon is <a href='https://www.flaticon.com/packs/voting-and-elections-9' target='_blank' rel='noreferrer'>IconBaandar's  Voting And Elections pack</a>.</p>
        </ModalWindow>
    );
}
export const ConfigModal = (props) => {
return (
        <ModalWindow show={props.show} onClose={props.onClose} title='Configuration Options'>
            <p>CONFIG COMING SOON!</p>
        </ModalWindow>
    );
}

const ModalWindow = (props) => {
    return (
        <Modal show={props.show} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.modalbody}>
            {props.children}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
}